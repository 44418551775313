import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import {
  buyerorderInvoiceDetails,
} from "../../state/action/orderAction";
import moment from "moment";
import { styled } from "@mui/system";
import Logo from "../../assets/logoinvoice.png";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";



function SellerOrderDetails() {
  const [sellerOrderDetail, setSellerOrderDetail] = useState([]);
  const [productData, setProductdata] = useState([]);
  const [ordersData, setOrdersdata] = useState([]);
  const [sellercomapny, setSellerComapny] = useState([]);
  const [buyercomapny, setBuyerComapny] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [bankDetail, setBankDetail] = useState([]);
  const [terms, setTerms] = useState([]);

  const pdfExportComponent = useRef(null);

  const downloadPdf = async () => {
    if (pdfExportComponent.current) {
      // navigate(-1);
      //how to change download pdf file name
      await pdfExportComponent.current.save(
        "Invoice.pdf" + ".pdf" + "?download=true"
      );
    }
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }



  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    getInvoiceDetails(state);
  }, [state]);

  const getInvoiceDetails = async () => {
    const res = await buyerorderInvoiceDetails(state);
    if (res && res.status) {
      setOrderDetail(res?.data?.userData);
      setSellerOrderDetail(res?.data?.sellerData);
      setProductdata(res?.data?.productData);
      setOrdersdata(res?.data?.orderData);
      setSellerComapny(res?.data?.sellerCompanyDatas);
      setBuyerComapny(res?.data?.usercompanyData);
      setBankDetail(res?.data?.getBankDetails);
      setTerms(res?.data?.gettnc);
    }
  };

  const total = ordersData?.total
    ? ordersData?.total.toLocaleString("en-IN", {
      // style: "currency",
      currency: "INR",
    })
    : "";

  const amount = ordersData?.amount
    ? ordersData?.amount.toLocaleString("en-IN", {
      // style: "currency",
      currency: "INR",
    })
    : "";

  return (
    <>
      <Box sx={{ overflowY: "hidden !imporatant" }}>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Invoice
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Invoice Detail
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Detail
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text="Download Invoice"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    downloadPdf();
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          scale={0.7}
          // fileName={userName}-{ordersData?.orderNumber}
          fileName={`${buyercomapny.companyName}_${ordersData?.orderNumber}`} // userName
        >
          <Box
            sx={{
              border: "3px solid #black",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderTopRightRadius: "25px",
                  borderTopLeftRadius: "25px",
                  border: 0,
                  borderColor: "#eaeaea",
                  paddingLeft: "0px",
                  paddingTop: "10px",
                }}
              >
                <Box
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        md: "600px",
                        lg: "1200px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        marginBottom: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        border: "3px solid #black",
                      }}
                    >
                      <Grid
                        container
                        spacing={8}
                        style={{ marginBottom: "30px" }}
                      >
                        <Grid item sm={6} xs={12}>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ marginLeft: "35px" }}
                            >
                              <img
                                src={Logo}
                                alt="logo"
                                style={{
                                  width: "250px",
                                  height: "90px",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "22px",
                                lineHeight: "31px",
                                color: "black",
                                marginTop: "20px",
                                marginLeft: "41px",
                              }}
                            >
                              {/* {sellercomapny?.companyName} */}
                              PURI POLYMERS
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              Behind Divya Bhaskar Office, C-602 Sixth Floor,
                              Titanium Business Park, Wing C, Makarba Road, Makarba,
                              Ahmedabad, Gujarat, 382210
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              Email : puripolymers@gmail.com
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              GSTIN : 24ABDFP2006M1ZF
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item sm={6} xs={12} style={{ paddingRight: '50px' }}>
                          <Grid item sm={12} xs={12}>
                            <Typography
                              sx={{
                                fontSize: "23px",
                                fontWeight: "Bold",
                                color: "#000",
                                marginTop: "13px",
                                marginBottom: "14px",
                                // display: "flex",
                                // justifyContent: "center",
                              }}
                            >
                              Proforma Invoice
                            </Typography>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                }}
                              >
                                Order No. : {ordersData?.orderNumber}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginRight: "64px",
                                }}
                              >
                                Date:{" "}
                                {moment(ordersData.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginLeft: "43px",
                                }}
                              >
                                {ordersData && ordersData.paymentTerms
                                  ? `Payment Terms: ${ordersData.paymentTerms}`
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginRight: "9px",
                                }}
                              >
                                Location: {ordersData.cityName},{" "}
                                {ordersData.stateName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={8}
                        style={{ marginBottom: "30px", marginTop: "0px", }}
                      >
                        <Grid item sm={6} xs={12} style={{ paddingTop: '20px' }}>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                // fontWeight: "bold",
                                fontSize: "17px",
                                lineHeight: "26px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              Buyer
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontSize: "18px",
                                lineHeight: "25px",
                                color: "black",
                                fontWeight: "700",
                                marginLeft: "41px",
                              }}
                            >
                              {buyercomapny?.companyName}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              {buyercomapny?.address},{" "}{buyercomapny?.cityName},{" "}{buyercomapny?.stateName}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              GSTIN : {buyercomapny?.gstNumber}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              Place Of Supply: GJ(24)
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item sm={6} xs={12} style={{ paddingRight: '50px', paddingTop: '20px' }}>
                          <Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: '29px',
                                color: "black",
                                // display: "flex",
                                // justifyContent: "center",
                                // marginRight: "110px",
                              }}
                            >
                              Ship To
                            </Typography>
                          </Grid>

                          <Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                // marginTop: "-7px",
                                // display: "flex",
                                // justifyContent: "center",
                                // marginRight: "53px",
                              }}
                            >
                              {/* {ordersData?.deliveryAddress} */}
                              {ordersData?.shippingline1} <br />
                              {ordersData?.shippingline2} <br />
                              {ordersData?.shippingline3} <br />
                              {ordersData?.shippingcity}<br />
                              {ordersData?.shippingstate}{" "}
                              {ordersData?.shippingpincode}
                              {/* ,{" "}{buyercomapny?.cityName},{" "}{buyercomapny?.stateName} */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          border: 1,
                          borderColor: "#eaeaea",
                          marginRight: "20px",
                          width: "90%",
                          justifyContent: "center",
                          display: "flex",
                          marginLeft: "40px",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                        >
                          <Table
                            aria-label="simple table"
                            sx={{ paddingLeft: "0!important" }}
                          >
                            <TableHead sx={{ paddingLeft: "0!important" }}>
                              <TableRow
                                sx={{
                                  backgroundColor: "#f6f9fb",
                                  paddingLeft: "0!important",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "60px",
                                  }}
                                >
                                  Sr.No
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "180px",
                                  }}
                                >
                                  Item
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "80px",
                                  }}
                                >
                                  HSN/SAC
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "100px",
                                  }}
                                >
                                  Quantity(MT)
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "50px",
                                  }}
                                >
                                  Rate(Kg)
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    textAlign: 'end !important',
                                    width: "100px",
                                  }}
                                >
                                  Amount
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                backgroundColor: "#fff",
                                paddingLeft: "0!important",
                              }}
                            >
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "60px",
                                  }}
                                >
                                  1
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "180px",
                                  }}
                                >
                                  {productData?.productName}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "80px",
                                  }}
                                >
                                  {productData && productData?.hsn
                                    ? productData?.hsn
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "100px",
                                  }}
                                >
                                  {ordersData?.quantity}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "50px",
                                  }}
                                >
                                  {ordersData?.productPrice}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    textAlign: 'end !important',
                                    width: "100px",
                                  }}
                                >
                                  {amount}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Box
                        sx={{
                          marginRight: "40px",
                          marginBottom: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            marginLeft: "auto",
                            maxWidth: "250px",
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              Amount
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                                marginRight: '2px'
                              }}
                            >
                              INR {amount}
                            </Typography>
                          </Box>
                          {ordersData?.SGST != 0 ? (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                SGST @ 9%
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#000000c7",
                                }}
                              >
                                {ordersData?.SGST}
                              </Typography>
                            </Box>
                          ) : null}
                          {ordersData?.CGST != 0 ? (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                CGST @ 9%
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#000000c7",
                                }}
                              >
                                {ordersData?.CGST}
                              </Typography>
                            </Box>
                          ) : null}
                          {ordersData?.IGST != 0 ? (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                IGST @18%
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#000000c7",
                                }}
                              >
                                {ordersData?.IGST}
                              </Typography>
                            </Box>
                          ) : null}

                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            {ordersData?.tcs != "0" ? (
                              <>
                                <Typography
                                  sx={{
                                    paddingBottom: "3px",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    color: "#000",
                                  }}
                                >
                                  TCS
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#000000c7",
                                    marginRight: '3px',
                                  }}
                                >
                                  + {ordersData?.tcs}
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            {ordersData?.tds != "0" && ordersData?.tds != "0.00" ? (
                              <>
                                <Typography
                                  sx={{
                                    paddingBottom: "3px",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    color: "#000",
                                  }}
                                >
                                  TDS
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#000000c7",
                                    marginRight: '3px',
                                  }}
                                >
                                  - {ordersData?.tds}
                                </Typography>
                              </>
                            ) : ("")}
                          </Box>


                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                              borderTop: 1,
                              borderColor: "#eaeaea",
                              paddingLeft: "2px",
                              paddingTop: "2px",
                              marginTop: "6px",
                            }}
                          >
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#000",
                                marginTop: "7px",
                              }}
                            >
                              Total
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                                marginTop: "7px",
                                marginRight: '2px'
                              }}
                            >
                              INR {total}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Grid item sm={6} xs={12} sx={{ paddingLeft: "10px" }}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginTop: "20px",
                                marginLeft: "30px",
                              }}
                            >
                              Terms & Condition
                            </Typography>

                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "23px",
                                color: "black",
                                marginLeft: "30px",
                              }}
                            >
                              {terms.description1} <br />
                              {terms.description2} <br />
                              {terms.description3} <br />
                              {terms.description4} <br />
                              {terms.description5}

                            </Typography>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginTop: "20px",
                                marginLeft: "30px",
                              }}
                            >
                              Bank Details
                            </Typography>

                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "23px",
                                color: "black",
                                marginLeft: "30px",
                              }}
                            >
                              Bank Name: {bankDetail?.bankName}
                              <br />
                              Account Number:- {bankDetail?.accountNumber}
                              <br />
                              IFSC Code:- {bankDetail?.ifscCode}
                              <br />
                              Address: {bankDetail?.address}
                            </Typography>
                          </Grid>

                          {ordersData?.remarks && (<Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                lineHeight: "21px",
                                marginTop: "0px",
                                marginLeft: "30px",
                                display: 'flex',

                              }}
                            >
                              <div style={{
                                fontWeight: "bold",
                                color: "black",
                                width: '100px'
                              }}> Remarks :</div>
                              <div style={{ width: '-webkit-fill-available' }}>
                                {ordersData?.remarks}
                              </div>
                            </Typography>


                          </Grid>)}

                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                              marginTop: "20px",
                              marginBottom: "10px",
                              marginLeft: "45px",
                            }}
                          >
                            * This is computer generated document, does not require any signature.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </PDFExport>
      </Box>
    </>
  );
}

export default SellerOrderDetails;
